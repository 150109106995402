<template>
  <div class="container">
    <SelectRider @selectRider="handleSelectRider"/>
    <div class="right-container">
      <div class="list-container" :style="{height: height}">
        <div class="header">
          <div class="text">代用名 骑手运单记录</div>
          <div class="return">
            <el-button type="text" @click="goBack">
              <span class="returnBtn">返回</span>
            </el-button>
          </div>
        </div>
        <div class="toolbar">
          <el-input placeholder="查询关键字" v-model="keyword" :clearable="true" :style="{ width:'250px',marginTop: '15px',float: 'right'}">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <el-table :data="riderOrderDetail" highlight-current-row ref="table">
          <el-table-column label="序号" align="center" width="60px" prop="id"></el-table-column>
          <el-table-column label="门店名称" align="center" width="160px" prop="store"></el-table-column>
          <el-table-column label="运单号" align="center" width="120px" prop="number"></el-table-column>
          <el-table-column label="配送平台" align="center" width="120px" prop="platform"></el-table-column>
          <el-table-column label="距离" align="center" width="70px" prop="distance"></el-table-column>
          <el-table-column label="用时" align="center" width="70px" prop="time"></el-table-column>
          <el-table-column label="超时" align="center" width="70px" prop="timeout"></el-table-column>
          <el-table-column label="运费" align="center" width="80px" prop="freight"></el-table-column>
          <el-table-column label="小费" align="center" width="80px" prop="tip"></el-table-column>
          <el-table-column label="小计" align="center" width="80px" prop="total"></el-table-column>
          <el-table-column label="下单时间" align="center" width="140px" prop="orderTime"></el-table-column>
          <el-table-column label="接单时间" align="center" width="140px" prop="orderAcceptTime"></el-table-column>
          <el-table-column label="到店时间" align="center" width="140px" prop="arrivalTime"></el-table-column>
          <el-table-column label="完成时间" align="center" width="140px" prop="completionTime"></el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="80px">
            <template slot-scope="scope">
              <el-button type="text" @click="view(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="100"
          :style="{marginTop: '15px'}">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {getRiderList} from "@/api/delivery/deliveryInfo";
import getRiderOrderDetail from "@/mock/data/getRiderOrderDetail";
import SelectRider from "@/views/components/SelectRider";

export default {
  name: "selfPickupGoodsRiderOrderDetail",
  components: {SelectRider},
  data() {
    return {
      height: document.documentElement.clientHeight - 60 + "px",
      selectedStore:[],
      keyword:'',
      riderOrderDetail:[],
    };
  },
  created() {
    this.getRiderOrderDetail();
  },
  mounted() {

  },
  methods: {
    getRiderOrderDetail() {
      this.riderOrderDetail = getRiderOrderDetail.data;
      console.log(this.riderOrderDetail);
      // getRiderList({keyword:this.keyword}).then(result => {
      //   if (result) {
      //     console.log(result);
      //   }
      // });
    },
    handleSelectRider(e) {
      console.log(e);
      this.selectedRider = e;
      this.getRiderOrderDetail();
    },
    view(item){
      console.log(item);
    },
    goBack(){
      this.$router.go(-1);
    }
  },
};
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
}
.right-container{
  float:left;
  width:calc(100% - 240px);
  height: 100%;
}
.list-container{
  width: 100%;
  border: 1px solid #ddd;
  padding: 0px 20px;
  .header{
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding-left: 5px;
    border-bottom: 1px solid #ddd;
    .text{
      float: left;
      font-size: 16px;
    }
    .return{
      float: right;
    }
  }
  .toolbar{
    width: 100%;
    height: 60px;
  }
}
.returnBtn{
  font-size: 15px;
}
</style>
