var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("SelectRider", { on: { selectRider: _vm.handleSelectRider } }),
      _c("div", { staticClass: "right-container" }, [
        _c(
          "div",
          { staticClass: "list-container", style: { height: _vm.height } },
          [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "text" }, [
                _vm._v("代用名 骑手运单记录"),
              ]),
              _c(
                "div",
                { staticClass: "return" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.goBack } },
                    [_c("span", { staticClass: "returnBtn" }, [_vm._v("返回")])]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "toolbar" },
              [
                _c(
                  "el-input",
                  {
                    style: {
                      width: "250px",
                      marginTop: "15px",
                      float: "right",
                    },
                    attrs: { placeholder: "查询关键字", clearable: true },
                    model: {
                      value: _vm.keyword,
                      callback: function ($$v) {
                        _vm.keyword = $$v
                      },
                      expression: "keyword",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-table",
              {
                ref: "table",
                attrs: {
                  data: _vm.riderOrderDetail,
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "序号",
                    align: "center",
                    width: "60px",
                    prop: "id",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "门店名称",
                    align: "center",
                    width: "160px",
                    prop: "store",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "运单号",
                    align: "center",
                    width: "120px",
                    prop: "number",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "配送平台",
                    align: "center",
                    width: "120px",
                    prop: "platform",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "距离",
                    align: "center",
                    width: "70px",
                    prop: "distance",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "用时",
                    align: "center",
                    width: "70px",
                    prop: "time",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "超时",
                    align: "center",
                    width: "70px",
                    prop: "timeout",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "运费",
                    align: "center",
                    width: "80px",
                    prop: "freight",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "小费",
                    align: "center",
                    width: "80px",
                    prop: "tip",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "小计",
                    align: "center",
                    width: "80px",
                    prop: "total",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "下单时间",
                    align: "center",
                    width: "140px",
                    prop: "orderTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "接单时间",
                    align: "center",
                    width: "140px",
                    prop: "orderAcceptTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "到店时间",
                    align: "center",
                    width: "140px",
                    prop: "arrivalTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "完成时间",
                    align: "center",
                    width: "140px",
                    prop: "completionTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    align: "center",
                    label: "操作",
                    width: "80px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.view(scope.row)
                                },
                              },
                            },
                            [_vm._v("详情")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("el-pagination", {
              style: { marginTop: "15px" },
              attrs: {
                background: "",
                layout: "total, prev, pager, next, jumper",
                total: 100,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }